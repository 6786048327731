import Vue from 'vue'
import App from './App.vue'
import router from './router'
import crypto from 'crypto'
import settings from "./backend/LocalSettings";
import VueResource from "vue-resource";
import locale from "element-ui/lib/locale/lang/ro"
import VueSignaturePad from "vue-signature-pad"
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import BootstrapVue from 'bootstrap-vue'
import moment from "moment";

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import vuetify from './plugins/vuetify'
import AddToCalendar from 'vue-add-to-calendar';

Vue.use(AddToCalendar);
Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(VueResource);
Vue.use(ElementUI, { locale });
Vue.use(VueSignaturePad);


Vue.http.options.xhr = { withCredentials: true };
Vue.http.options.emulateJSON = true;
Vue.http.headers.common["Token"] = settings.get_token();
// Vue.http.headers.common["Sursa"] = settings.get_local_url();


Vue.http.options.root = settings.BASE_URL;

Vue.filter('momentformat', function (value) {
  return value;
});

Vue.filter('DataOraFaraSec', function (value) {
  let formatata = moment(value);
  if (formatata.isValid()) return formatata.format("DD.MM.YYYY HH:mm")
  else return "-"
});

Vue.filter('format_money', function (value) {
  if (value !== undefined) {
    var nr = parseFloat(value)
    return numeral(nr).format('0,0.00')
  } else {
    return null
  }
})

Vue.prototype.$has_right = function (rightKey) {
  var ret = false;
  var drepturiDecoded = settings.get_drepturi();
  var numarUnic = (drepturiDecoded.length - 1) * (458 + 73 - 23)
  var caractereUnice = 'nimic' + numarUnic
  var verificareCrc = crypto.createHash('md5').update(Buffer.from(caractereUnice)).digest('hex')
  if (drepturiDecoded.indexOf(verificareCrc) !== -1) {
    var indexDrept = drepturiDecoded.indexOf(rightKey)
    if (indexDrept !== -1) ret = true;
  }
  return ret;
}


new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
