import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      name: "home",
      component: () => import("./pages/Home.vue"),
    },

    // {
    //   path: "/home",
    //   name: "new_home",
    //   component: () => import("./pages/Interfata_noua/Home.vue")
    // },
    // {
    //   path: '/home/amanare/:id',
    //   name: 'new_amanare', 
    //   component: () => import('./pages/interfata_noua/Amanare.vue')
    // },
    // {
    //   path: '/home/anulare/:id',
    //   name: 'new_anulare',
    //   component: () => import('./pages/interfata_noua/Anulare.vue')
    // },
    // {
    //   path: '/home/istoric/:secure_email',
    //   name: 'new_istoric',
    //   component: () => import('./pages/interfata_noua/Istoric_programari.vue')
    // },
    {
      path: '/amanare/:id',
      name: 'amanare',
      component: () => import('./pages/Amanare.vue'),
    },

    {
      path: "/anulare/:id",
      name: "anulare",
      component: () => import('./pages/Anulare.vue')
    },

    {
      path: '/no_page',
      name: "nopage",
      component: () => import('./pages/nu_exista.vue')
    },

    {
      path: '/istoric/:secure_email',
      name: 'istoric',
      component: () => import('./pages/Istoric_programari.vue')
    },
    {
      path: '/programari_viitoare/:secure_email',
      name: 'programari_viitoare',
      component: () => import('./pages/Programari_viitoare.vue')
    },
    { path: '/seteaza_agent', name: 'seteaza_agent', component: () => import('./pages/Seteaza_agent.vue') },
    { path: '/politica_de_confidentialitate', name: 'politica_de_confidentialitate', component: () => import('./pages/Politica_confidentialitate.vue') },

  ],
});
