<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import settings from "@/backend/LocalSettings";

export default {
  name: "App",
  methods: {
    async check_version() {
      var local_version = settings.version;
      var server_version = await this.post("version");
      if (local_version != server_version) {
        console.log('Versiune noua')
        window.location.reload(true);

        // this.$notify({
        //   title: "Atentie",
        //   message:
        //     'A aparut o noua versiune - <button onclick="window.location.reload()">Upgrade</button> ',
        //   dangerouslyUseHTMLString: true,
        //   type: "warning",
        //   duration: 0,
        // });gata
      }
      // console.log(local_version, server_version)

    },
    post: async function (url, args = {}) {
      var response = await this.$http.post(url, args);
      return response.bodyText;
    },
  },
  mounted() {
    //verificam daca exista o versiune mai noua
    this.check_version();
  },
};
</script>

<style lang='less'>
@albastru-deschis: #44b0cc;
#app {
   
    background-color: #fff;
    background-image: linear-gradient(135deg, transparent 0%, #fff 25%, #fff 75%, transparent 100%), url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAAIklEQVQoU2N89+7dfwYsQEhIiBEkzDgkFGDzAbIY2Cv4AACvrBgJjYNGfwAAAABJRU5ErkJggg==);
    background-repeat: repeat;
}

.text-albastru {
  color: @albastru-deschis;
}

.bg-albastru {
  background-color: @albastru-deschis;
}

div.titlu {
  background-color: #0d7aa3;
  h1 {
    color: white;
    padding: 8px 15px;
    font-family: "Lato", sans-serif;
    font-weight: 300;
  }
  .btn-adauga {
    float: right;
    height: 72px;
    padding-left: 50px;
    padding-right: 50px;
    background: #34c2e4; /* Old browsers */
    background: -moz-linear-gradient(
      top,
      #34c2e4 0%,
      #42b6df 50%,
      #4fabda 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      top,
      #34c2e4 0%,
      #42b6df 50%,
      #4fabda 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to bottom,
      #34c2e4 0%,
      #42b6df 50%,
      #4fabda 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#34c2e4', endColorstr='#4fabda',GradientType=0 ); /* IE6-9 */
    border: none;
    border-radius: 0%;
    margin: 2px;
  }
}

html {
  height: 100%;
  background-position: center;
  background-size: cover;
  background-position: fixed;
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth !important;
}

body {
  background-color: transparent !important;
  // background-image: url("https://evio.anvelopeinstoc.ro/static/img/EVIO_background.80b96d9.jpg");
  // opacity: 70%;
  // filter: blur();
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.my-dialog-class .el-dialog {
  width: 50%;
}
.full-width {
  width: 100%;
}

@media only screen and (max-width: 600px) {
  .my-dialog-class .el-dialog {
    width: 90vw !important;
}

}
</style>
